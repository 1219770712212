import { log } from "console";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { clearToken } from "../utils/localstorage";
import api from "../config/api";
export type TActions =
  | {
      type: "AUTHENTICATE";
      payload: {
        isAuthenticated: boolean;
      };
    }
  | {
      type: "STORE_LOAN_ELIGIBILITY";
      payload: TStore["loanEligibility"];
    };
type TStore = {
  isAuthenticated: boolean;
  user: {
    balance: string;
    id: number;
    name: string;
    email: string;
    address?: string;
    token: string;
  };
  loanEligibility: {
    custName: string;
    mobile: string;
    insuranceProvider: string;
    policyNumber: string;
    premiumInstallment: string;
    policyStartDate: string;
    surrenderValue: string;
    loanAmount: string;
    emi: string;
    interest: string;
    tenure: string;
  };
};

type TStoreContext = {
  state: TStore;
  dispatch: Dispatch<TActions>;
};

export const StoreContext = createContext<any>({});
const initialState: TStore = {
  isAuthenticated: false,
  user: {
    id: null,
    name: "",
    balance: "0",
    email: "",
    address: "",
    token: "",
  },
  loanEligibility: {
    custName: "",
    mobile: "",
    insuranceProvider: "",
    policyNumber: "",
    premiumInstallment: "",
    policyStartDate: "",
    surrenderValue: "",
    loanAmount: "",
    emi: "",
    interest: "",
    tenure: "",
  },
};

const reducer = (state: TStore = initialState, action: TActions): TStore => {
  console.log(action);
  switch (action.type) {
    case "AUTHENTICATE": {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "STORE_LOAN_ELIGIBILITY": {
      return {
        ...state,
        loanEligibility: action.payload,
      };
    }
    // case "LOGOUT": {
    //   clearToken();
    //   return initialState;
    // }
    default:
      return state;
  }
};
export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StoreContext.Provider>
  );
};
export const useStore = () => useContext(StoreContext) as TStoreContext;
