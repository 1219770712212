export const routeEnum = {
  landing: "/",
  home: "/",
  login: "/login",
  signup: "/signup",
  addItem: "/item/add",
  item: "/item",
  wallet: "/wallet",
  itemDetails: "/item/:id",
  loanEligibility: "/loan-eligibility",
  applicationReceived: "/application-received",
};
