import request, { BASE_URL, resolveRequest } from "./axios";
interface TCreateItem {
  name: string;
  startingPrice: number;
  sellerId: number;
  time: number;
  timeFrameType: "minutes" | "hours" | "days";
}
interface TLoanEligible {
  "custMobile": string,
  "policyName": string,
  "policyNumber": string
}
interface TRegisterUser {
  name: string;
  email: string;
  password: string;
}
interface TUpdateUserById {
  name: string;
  address: string;
}
interface TUpdateBalance {
  balance: number;
}
interface TLoginUser {
  agentMobile: string;
}
const users = {
  getUser: (id: number) => resolveRequest(request.get(`/users/${id}`, {})),
  getMyProfile: () => resolveRequest(request.get(`/users/me`, {})),
  getAllUsers: () => resolveRequest(request.get(`/users`, {})),
  registerUser: (body: TRegisterUser) =>
    resolveRequest(request.post(`/users`, body)),
  updatedUserById: (body: TUpdateUserById) =>
    resolveRequest(request.put(`/users`, body)),
};
const auth = {
  getUser: () => resolveRequest(request.get(`/auth`, {})),
  loginUser: (body: TLoginUser) => resolveRequest(request.post(`/authenticate`, body)),
  updatePassword: (body) =>
    resolveRequest(request.patch(`/auth/update-password`, body)),
};

const loan = {
  loanEligible: (body: TLoanEligible) => resolveRequest(request.post(`/loan-eligible`, body)),
}
const api = {
  users,
  auth,
  loan
};
export default api;
