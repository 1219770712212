import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
export default function Footer() {
  return (
    <div
      style={{
        flex: "0 0 auto",
        backgroundColor: "#0559FA",
        padding: "10px 24px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div
          className="able-money"
          style={{ marginBottom: 10, color: "white" }}
        >
          Able Money
        </div>
        <a
          href="https://www.facebook.com/ablemoney"
          style={{ padding: "0px 24px 0px 0px" }}
        >
          <FacebookIcon fontSize="medium" style={{ color: "white" }} />
        </a>
        {/*<a href="http://wa.me/9980526430">*/}
        {/*    <WhatsAppIcon fontSize="medium" style={{ color: "white" }} />*/}
        {/*</a>*/}
      </div>
      <div>
        <div className="footer-contact-text">Contact</div>
        <div className="footer-icons">
          <EmailIcon style={{ marginRight: 10 }} />
          <a
            href="mailto:chandan@ablemoney.in"
            style={{
              textDecoration: "none",
              textTransform: "none",
              color: "white",
            }}
          >
            chandan@ablemoney.in
          </a>
        </div>
        <br />

        <div className="footer-icons">
          <CallIcon style={{ marginRight: 10 }} /> +91 9980526430
        </div>
      </div>
    </div>
  );
}
