import { ToastContainer } from "react-toastify";
import Navbar from "./components/Layout/Navbar";
import AppRoutes from "./components/Routing/AppRoutes";
import "./scss/main.scss";
function App() {
  return (
    <div className="App">
      <AppRoutes />
      {/* toast notification for api calls */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
