import React from "react";
import {
  Routes,
  Route,
  RouteProps,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import LoginForm from "../Forms/LoginForm";
import RegistrationForm from "../Forms/RegistrationForm";
import PrivateRoute from "./PrivateRoute";
import { routeEnum } from "../../utils/enum";
import HeroSection from "../Homepage/HeroSection";
import Partners from "../Homepage/Partners";
import Navbar from "../Layout/Navbar";
import LoanEligibility from "../Homepage/LoanEligibility";
import Footer from "../Layout/Footer";
import ApplicationReceived from "../Homepage/ApplicationReceived";

function AppRoutes(props) {
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* {showNav && <Navbar />} */}
      <Routes>
        <Route
          path={routeEnum.home}
          element={
            <>
              <Navbar />
              <HeroSection />
              <Partners />
              <Footer />
            </>
          }
        />
        <Route path={routeEnum.login} element={<LoginForm />} />
        <Route path={routeEnum.signup} element={<RegistrationForm />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={routeEnum.loanEligibility}
            element={
              <>
                <Navbar />
                <LoanEligibility />
              </>
            }
          />
          <Route
            path={routeEnum.applicationReceived}
            element={
              <>
                <Navbar />
                <ApplicationReceived />
                <Footer />
              </>
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default AppRoutes;
