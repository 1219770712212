import { Button } from "@mui/material";
import React from "react";
import { routeEnum } from "../../utils/enum";
import { useNavigate } from "react-router-dom";

export default function ApplicationReceived() {
  const navigate = useNavigate();

  return (
    <div style={{ flex: 1 }}>
      {/* <div
        style={{
          height: 190,
          backgroundColor: "#E5EEFF",
          padding: "0px 20%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ color: "#404040", fontSize: 36 }}>Add New policy</div>
        <div style={{ fontSize: 16, marginTop: 10 }}>
          Home. Manage. <span style={{ color: "#979797" }}>Add New</span>
        </div>
      </div> */}
      <div style={{ textAlign: "center", marginTop: "8%" }}>
        <img src="/add-new-policy-tick.svg" alt="" />
        <div style={{ fontSize: 28, marginBottom: 48 }}>
          Loan Application Received
        </div>

        <Button
          type="submit"
          variant="contained"
          size="medium"
          className="regular-filled-blue-button"
          onClick={() => navigate(routeEnum.loanEligibility)}
        >
          Add New Case
        </Button>
      </div>
    </div>
  );
}
