import { useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useStore } from "../../store/useStore";
import { useState } from "react";

export default function LoanOfferBreakup({ onNext }) {
  const navigate = useNavigate();
  const { state, dispatch } = useStore();
  const [checked, setChecked] = useState(false);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontSize: 21, fontWeight: 600, marginBottom: 20 }}>
        Eligibility Details 🌟
      </div>
      <div
        style={{
          background: "white",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 10,
          rowGap: 24,
          padding: 24,
        }}
      >
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Loan Amount (Max){" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500, color: "#008C06" }}>
            ₹ {state.loanEligibility.loanAmount}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Surrender Value{" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            ₹ {state.loanEligibility.surrenderValue}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            EMI
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            ₹ {Number(state.loanEligibility.emi).toFixed(2)}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Interest
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {Number(state.loanEligibility.interest).toFixed(2)}%
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Months
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {state.loanEligibility.tenure}
          </div>
        </div>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        }
        label="I acknowledge to apply for loan against insurance with the given T&C."
      />

      <Button
        type="submit"
        variant="contained"
        size="medium"
        disabled={!checked}
        className="regular-filled-blue-button"
        onClick={onNext}
      >
        Apply Now
      </Button>
    </div>
  );
}
