import React, { useState } from "react";
import CustomerDetailsForm from "../Forms/CustomerDetailsForm";
import EligibilityDetails from "../Forms/EligibilityDetails";
import LoanOfferBreakup from "../Forms/LoanOfferBreakup";
import { routeEnum } from "../../utils/enum";
import { useNavigate } from "react-router-dom";
const StateText = {
  0: "Add Customer Details",
  1: "Eligibility",
  2: "Loan offer and break up",
};
export default function LoanEligibility() {
  const [curState, setCurState] = useState(0);
  const navigate = useNavigate();

  function onNext() {
    setCurState(curState + 1);
  }
  function onSubmit() {
    navigate(routeEnum.applicationReceived);
  }
  return (
    <div className="loan-form-container">
      <div className="form-header">{StateText[curState]}</div>
      <div className="input-forms">
        {curState === 0 && <CustomerDetailsForm onNext={onNext} />}
        {curState === 1 && <EligibilityDetails onNext={onNext} />}
        {curState === 2 && <LoanOfferBreakup onNext={onSubmit} />}
      </div>
    </div>
  );
}
