import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../../config/api";
import debounce from "../../utils/debounce";
import { useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { routeEnum } from "../../utils/enum";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useStore } from "../../store/useStore";

const RegisterSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number cannot exceed 10 digits"),
  policyNumber: Yup.string()
    .required("Policy number is required")
    .matches(/^[0-9]+$/, "Policy number must contain only digits"),
  policyName: Yup.string().required("Insurer name is required"),
});

export default function CustomerDetailsForm({ onNext }) {
  const navigate = useNavigate();
  const { state, dispatch } = useStore();

  return (
    <div>
      <div style={{ fontSize: 20 }}>Lets Get Started</div>
      <Formik
        initialValues={{
          mobile: "",
          policyNumber: "",
          policyName: "",
        }}
        validationSchema={RegisterSchema}
        onSubmit={debounce(async (values, { resetForm }) => {
          const response = await api.loan.loanEligible({
            custMobile: values.mobile,
            policyName: values.policyName,
            policyNumber: values.policyNumber,
          });
          if (response.status) {
            resetForm();
            dispatch({
              type: "STORE_LOAN_ELIGIBILITY",
              payload: response.body.response,
            });
            onNext();
            // navigate(routeEnum.login);
          }
        })}
      >
        {({ errors, touched }) => (
          <Form>
            {/* Mobile Field */}
            <Field name="mobile">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Mobile"
                  type="tel"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  error={touched.mobile && errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                />
              )}
            </Field>

            {/* Policy Number Field */}
            <Field name="policyNumber">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Policy Number"
                  type="string"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  error={touched.policyNumber && errors.policyNumber}
                  helperText={touched.policyNumber && errors.policyNumber}
                />
              )}
            </Field>

            {/* Policy Name Field */}
            <Field name="policyName">
              {({ field }) => (
                <FormControl sx={{ margin: 2, width: "100%" }}>
                  <InputLabel
                    id="policyName"
                    style={
                      touched.policyName && errors.policyName
                        ? { color: "#d32f2f" }
                        : {}
                    }
                  >
                    Select Life Insurance Provider Name
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="policyName"
                    id="policyName"
                    label="Select Life Insurance Provider Name"
                    fullWidth
                    // placeholder="Select..."
                    error={touched.policyName && errors.policyName}
                    // helperText={touched.policyName && errors.policyName}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem
                      value={"LIC (Life Insurance Corportation of India)"}
                    >
                      LIC (Life Insurance Corportation of India)
                    </MenuItem>
                    <MenuItem value={"Tata AIA Life Insurance"}>
                      Tata AIA Life Insurance
                    </MenuItem>
                    <MenuItem value={"HDFC Life Insurance"}>
                      HDFC Life Insurance
                    </MenuItem>
                    <MenuItem value={"Bajaj Allianz Insurance"}>
                      SBI Life Insurance
                    </MenuItem>
                    <MenuItem value={"ICICI Prudential Life Insurance"}>
                      ICICI Prudential Life Insurance
                    </MenuItem>
                    <MenuItem value={"Max Life Insurance"}>
                      Max Life Insurance
                    </MenuItem>
                    <MenuItem value={"Aviva Life Insurance"}>
                      Aviva Life Insurance
                    </MenuItem>
                  </Select>
                  <ErrorMessage
                    name="policyName"
                    component={() => (
                      <span
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          margin: "3px 14px 0px 14px",
                        }}
                      >
                        {errors.policyName}
                      </span>
                    )}
                  />
                </FormControl>
              )}
            </Field>

            <Button
              type="submit"
              variant="contained"
              size="medium"
              className="regular-filled-blue-button"
            >
              Get Details
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
