import { Link } from "react-router-dom";
import { useStore } from "../../store/useStore";
import Avatar from "@mui/material/Avatar";
import { routeEnum } from "../../utils/enum";
import { Button } from "@mui/material";

const Navbar = () => {
  const { state, dispatch } = useStore();
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <Link
            to={routeEnum.home}
            style={{ textDecoration: "none" }}
            onClick={() =>
              dispatch({
                type: "AUTHENTICATE",
                payload: {
                  isAuthenticated: false,
                },
              })
            }
          >
            {/* <img src="/Able-Money.svg"/> */}
            <span className="able-money">Able Money</span>
          </Link>
          {!state.isAuthenticated && (
            <div
              style={{ display: "flex", gap: 32, marginLeft: 24, fontSize: 16 }}
            >
              {/*****   Commented the nav bar links  *****/}
              {/*<span style={{ color: "#0559FA" }}>Home</span>*/}
              {/*<span>Join as agent</span>*/}
              {/*<span>Contact</span>*/}
            </div>
          )}
          {/* {state.isAuthenticated && (
            <div
              style={{ display: "flex", gap: 32, marginLeft: 24, fontSize: 16 }}
            >
              <span style={{ color: "#0559FA" }}>Manage</span>
              <span>File Manager</span>
              <span>Support</span>
            </div>
          )} */}
        </div>
        {!state.isAuthenticated && (
          <div className="nav-menu">
            <span className="nav-item">
              <Link to={routeEnum.login} className="nav-links">
                <Button
                  variant="outlined"
                  style={{
                    width: 150,
                    border: "1px solid #0559FA",
                    color: "#0559FA",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  For agents
                </Button>
              </Link>
            </span>
            {/*<li className="nav-item">*/}
            {/*  <Link*/}
            {/*    to={routeEnum.signup}*/}
            {/*    onClick={(e) => {*/}
            {/*      e.preventDefault();*/}
            {/*    }}*/}
            {/*    className="nav-links"*/}
            {/*  >*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      style={{*/}
            {/*        width: 150,*/}
            {/*        background: "#0559FA",*/}
            {/*        fontWeight: "bold",*/}
            {/*        textTransform: "none",*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Sign up*/}
            {/*    </Button>*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </div>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
