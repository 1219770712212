import { useNavigate } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { Button } from "@mui/material";
import { useStore } from "../../store/useStore";

export default function EligibilityDetails({ onNext }) {
  const navigate = useNavigate();
  const { state, dispatch } = useStore();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontSize: 21, fontWeight: 600, marginBottom: 20 }}>
        Customer Details
      </div>
      <div
        style={{
          background: "white",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 10,
          rowGap: 24,
          padding: 24,
        }}
      >
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Customer name
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {state.loanEligibility.custName}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Mobile No.
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            +91 {state.loanEligibility.mobile}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Insurance Provider
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {state.loanEligibility.insuranceProvider}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Policy No.{" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {state.loanEligibility.policyNumber}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Premium Installment{" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {state.loanEligibility.premiumInstallment}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Policy Start Date{" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {state.loanEligibility.policyStartDate}
          </div>
        </div>
      </div>

      <div
        style={{
          fontSize: 21,
          fontWeight: 600,
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        Eligibility Details 🌟
      </div>
      <div
        style={{
          background: "white",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 10,
          rowGap: 24,
          padding: 24,
        }}
      >
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Loan Amount (Max){" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500, color: "#008C06" }}>
            ₹ {state.loanEligibility.loanAmount}
          </div>
        </div>
        <div>
          <div style={{ fontSize: 14, color: "#404040", marginBottom: 8 }}>
            Surrender Value{" "}
          </div>
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            ₹ {state.loanEligibility.surrenderValue}
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        size="medium"
        className="regular-filled-blue-button"
        onClick={onNext}
      >
        Next
      </Button>
    </div>
  );
}
