import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../../config/api";
import { setToken } from "../../utils/localstorage";
import "react-tabs/style/react-tabs.css";
import { useStore } from "../../store/useStore";
import { useNavigate } from "react-router-dom";
import showNotification from "../../utils/notification";
import { InputAdornment, TextField } from "@mui/material";
import { routeEnum } from "../../utils/enum";

const LoginSchema = Yup.object().shape({
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number cannot exceed 10 digits"),
});

export default function LoginForm() {
  const { state, dispatch } = useStore();
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div className="login-left-container">
        <div>
          <div className="able-money" style={{ marginBottom: 30 }}>
            Able Money
          </div>

          <Formik
            initialValues={{ mobile: "" }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const response = await api.auth.loginUser({
                agentMobile: "" + values.mobile,
              });
              console.log(response);
              if (response.body.hasAccess) {
                resetForm();
                setToken(response.body.token);
                dispatch({
                  type: "AUTHENTICATE",
                  payload: {
                    isAuthenticated: response.body.hasAccess,
                  },
                });
                showNotification("Logged in", {
                  type: "success",
                });
                navigate(routeEnum.loanEligibility);
              } else {
                showNotification(
                  "This mobile number is not registered as agent",
                  {
                    type: "error",
                  }
                );
              }
            }}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form>
                <div>
                  <div className="login-header">
                    Log in with your phone number
                  </div>
                  <Field name="mobile">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Your phone number"
                        type="tel"
                        fullWidth
                        variant="outlined"
                        error={touched.mobile && errors.mobile}
                        helperText={touched.mobile && errors.mobile}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </div>
                <br />
                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{ backgroundColor: "#0559FA", width: "100%" }}
                >
                  Sign In
                </button>
                <div
                  style={{ color: "#707070", textAlign: "center", margin: 10 }}
                >
                  Can’t access?{" "}
                  <a
                    style={{
                      cursor: "pointer",
                      color: "#0559FA",
                      fontWeight: 600,
                      textDecoration: "none",
                    }}
                    href="http://wa.me/9980526430"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Help
                  </a>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="login-right-container">
        <div className="take-control-image">
          <img src="/login-image-hand.svg" />
          <div style={{ fontSize: 35, fontWeight: 400, marginTop: 20 }}>
            Take Control of your
            <br />
            <strong>AbleMoney</strong> cases
          </div>
        </div>
        <div className="image-container">
          <div style={{ fontSize: 25, marginBottom: 20 }}>
            Inside your account you can:
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div style={{ flex: 1 / 3, borderRight: "1px solid lightgrey" }}>
              <img
                src="/login-image-file.svg"
                alt="file cases"
                style={{ height: 40 }}
              />
              <div style={{ fontSize: 20, marginBottom: 20 }}>File Cases</div>
            </div>
            <div style={{ flex: 1 / 3, borderRight: "1px solid lightgrey" }}>
              <img
                src="/login-image-earnings.svg"
                alt="manage earnings"
                style={{ height: 40 }}
              />
              <div style={{ fontSize: 20, marginBottom: 20 }}>
                Manage Earnings{" "}
              </div>
            </div>
            <div style={{ flex: 1 / 3 }}>
              <img
                src="/login-image-docs.svg"
                alt="View docs"
                style={{ height: 40 }}
              />
              <div style={{ fontSize: 20, marginBottom: 20 }}>View docs</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
