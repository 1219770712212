import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { routeEnum } from "../../utils/enum";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function HeroSection() {
  return (
    <div className="hero-root-container">
      <div className="hero-banner-container">
        <div className="subtext">Easy, Safe & Protected</div>
        <div className="text">
          Get easy credit against
          <br /> your life insurance policy  🌟
        </div>

        <a href="https://wa.me/9980526430?text=Hi.%20I%20want%20to%20know%20about%20loan%20against%20insurance" target="_blank" style={{ textDecoration: "none" }}>
          {/*<Link to={routeEnum.login}>*/}
          <Button variant="contained" className="regular-filled-blue-button">
            <WhatsAppIcon
              fontSize="medium"
              style={{ color: "white", paddingRight: 6 }}
            />
            WhatsApp us
          </Button>
        </a>
        {/*</Link>*/}
      </div>
      <img src="/Hero.svg" className="hero-banner-image" />
    </div>
  );
}
