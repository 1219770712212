import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { routeEnum } from "../../utils/enum";

export default function Partners() {
  return (
    <div
      className="partners-root-container"
    >
      <div className="our-partners">Our Partners</div>
      <div
        className="partners-list"
      >
        <img
          src="/bajaj_finserv.svg"
          alt="bajaj_finserv"
        />
        <img
          src="/indusindBank.svg"
          alt="indusindBank"
        />
      </div>
    </div>
  );
}
